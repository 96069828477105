module.exports = [
	{
		id: 'en',
		title: 'English',
		isDefault: true,
	},
	// {
	// 	id: 'en',
	// 	title: 'English'
	// },
	// {
	// 	id: 'es',
	// 	title: 'Spanish'
	// },
];
