import React, {Component} from 'react';
import sanity from '@sanity/client';
import * as queries from '../api/queries';
import queryString from 'query-string';
import locales from '../utils/locales';

// Localization
const languages = [locales[0].id]; // last language in array is default;

const localize = value => {
	if (Array.isArray(value)) {
		return value.map(v => localize(v, languages));
	} else if (typeof value == 'object') {
		if (
			value._type === 'localeString' ||
			value._type === 'localeText' ||
			value._type === 'localeRichText'
		) {
			const language = languages.find(lang => value[lang]);
			return value[language];
		}
		return Object.keys(value).reduce((result, key) => {
			result[key] = localize(value[key], languages);
			return result;
		}, {});
	}
	return value;
};

// Routes
import Home from './Home';
import Product from './Product';
import Collection from './Collection';
import Page from './Page';
import Helmet from 'react-helmet';

const client = sanity({
	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
	dataset: process.env.GATSBY_SANITY_DATASET,
	token: process.env.GASTBY_SANITY_PREVIEW_API_TOKEN,
	useCdn: false,
	withCredentials: true,
});

const INITIAL_STATE = {
	draft: null,
	queryResult: null,
};

export default class Preview extends Component {
	constructor() {
		super();
		this.state = {...INITIAL_STATE};
	}

	componentDidMount = () => {
		const params = queryString.parse(this.props.location.search, {
			arrayFormat: 'comma',
		});

		if (!params.documentId) return this.throwError();

		client
			.fetch(queries.draftDocument(params.documentId))
			.then(draft => {
				this.setState({draft});

				switch (draft._type) {
					case 'homepage':
						return client
							.fetch(queries.draftHomepage(draft._id))
							.then(homepage => {
								this.setState({
									queryResult: localize(homepage),
								});
							})
							.catch(error => {
								this.throwError();
							});
					case 'page':
						return client
							.fetch(queries.draftPage(draft._id))
							.then(page => {
								this.setState({queryResult: localize(page)});
							})
							.catch(error => {
								this.throwError();
							});
					case 'product':
						return client
							.fetch(queries.draftProduct(draft._id))
							.then(product => {
								this.setState({
									queryResult: localize({product}),
								});
							})
							.catch(error => {
								this.throwError();
							});
					case 'collection':
						return client
							.fetch(queries.draftCollection(draft._id))
							.then(collection => {
								this.setState({
									queryResult: localize({collection}),
								});
							})
							.catch(error => {
								this.throwError();
							});
				}
			})
			.catch(error => {
				this.throwError();
			});
	};

	throwError = () => {
		alert('Uh oh. It looks like the Preview URL is broken.');
	};

	render() {
		const {queryResult, draft} = this.state;
		const {pageContext, pathContext, ...props} = this.props;

		if (!queryResult || !draft) {
			return <div className="pt10 pb10 tc"> <Helmet title="Preview - Genexa"/>Loading Preview…</div>;
		}

		switch (draft._type) {
			case 'homepage':
				return (
					<><Helmet title="Home Page Preview - Genexa"/>
						<Home
							{...props}
							pageContext={{
								...pageContext,
								...queryResult,
							}}
							pathContext={{
								...pathContext,
								...queryResult,
							}}
						/>
					</>

				);
			case 'page':
				return (
					<><Helmet title="Page Preview - Genexa"/>
						<Page
							{...props}
							pageContext={{
								...pageContext,
								...queryResult,
							}}
							pathContext={{
								...pathContext,
								...queryResult,
							}}
						/>
					</>
				);
			case 'product':
				return (
					<><Helmet title="Product Page Preview - Genexa"/>
						<Product
							{...props}
							pageContext={{
								...pageContext,
								...queryResult,
							}}
							pathContext={{
								...pathContext,
								...queryResult,
							}}
						/>
					</>
				);
			case 'collection':
				return (
					<><Helmet title="Collection Page Preview - Genexa"/>
						<Collection
							{...props}
							pageContext={{
								...pageContext,
								...queryResult,
							}}
							pathContext={{
								...pathContext,
								...queryResult,
							}}
						/>
					</>
				);
		}
	}
}
